import classes from './Resume.module.scss';

const Resume = () => {
    return (
        <div id="resume" className={classes.resume}>
            <h1>Resume &amp; CV</h1>
            My astounding, awe-inspiring resume goes [ here ].
        </div>
    )
}

export default Resume;